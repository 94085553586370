import { isFunction } from "lodash";
import { useCallback, useEffect, useMemo } from "react";

import { useDispatch, useSelector, useStore } from "../redux/hooks.js";
import { browserStorageActions } from "../redux/slices/browserStorageSlice.js";
import {
  BrowserStorageType,
  SafeBrowserStorages,
} from "../util/browserStorage.js";

export type BrowserStorageKey<V> = {
  keyName: string;
  default: V;
};

function safeStorageKey(key: BrowserStorageKey<unknown>): string {
  return `_hex_${key.keyName}`;
}

export function getStorageValue<V>(
  key: BrowserStorageKey<V>,
  storageType: BrowserStorageType,
): V {
  const keyDefault = key.default;
  const storageKey = safeStorageKey(key);
  const value = SafeBrowserStorages[storageType].getItem(storageKey);
  if (value == null) {
    return keyDefault;
  } else {
    try {
      return JSON.parse(value);
    } catch (err) {
      console.error(err);
      return keyDefault;
    }
  }
}

export function useBrowserStorageGetter<V>(
  storageType: BrowserStorageType,
  key: BrowserStorageKey<V>,
): () => V {
  const store = useStore();

  const keyDefault = key.default;
  const storageKey = safeStorageKey(key);

  return useCallback(() => {
    const value =
      store.getState().browserStorage[storageType][storageKey] ??
      SafeBrowserStorages[storageType].getItem(storageKey);

    if (value == null) {
      return keyDefault;
    } else {
      try {
        return JSON.parse(value);
      } catch (err) {
        console.error(err);
        return keyDefault;
      }
    }
  }, [keyDefault, storageKey, storageType, store]);
}

export function useBrowserStorageSetter<V>(
  storageType: BrowserStorageType,
  key: BrowserStorageKey<V>,
): (value: ((oldValue: V) => V) | V) => void {
  const dispatch = useDispatch();

  const storageKey = safeStorageKey(key);
  const valueGetter = useBrowserStorageGetter(storageType, key);

  return useCallback(
    (newValue: ((oldValue: V) => V) | V) => {
      const oldValue = valueGetter();
      newValue = isFunction(newValue) ? newValue(oldValue) : newValue;

      if (newValue === oldValue) {
        return;
      }

      const jsonValue = JSON.stringify(newValue);
      dispatch(
        browserStorageActions.setValue({
          storageType,
          key: storageKey,
          value: jsonValue,
        }),
      );

      if (newValue === undefined) {
        SafeBrowserStorages[storageType].removeItem(storageKey);
      } else {
        SafeBrowserStorages[storageType].setItem(storageKey, jsonValue);
      }
    },
    [valueGetter, dispatch, storageType, storageKey],
  );
}

export function useBrowserStorageValue<V>(
  storageType: BrowserStorageType,
  key: BrowserStorageKey<V>,
): V {
  const dispatch = useDispatch();

  const storageKey = safeStorageKey(key);
  const keyDefault = key.default;

  const reduxValue = useSelector(
    (state) => state.browserStorage[storageType][storageKey],
  );

  const reduxValueWithStorage = useMemo(
    () => reduxValue ?? SafeBrowserStorages[storageType].getItem(storageKey),
    [reduxValue, storageKey, storageType],
  );

  useEffect(() => {
    if (reduxValue == null && reduxValueWithStorage != null) {
      dispatch(
        browserStorageActions.setValue({
          storageType,
          key: storageKey,
          value: reduxValueWithStorage,
        }),
      );
    }
  }, [dispatch, storageKey, reduxValue, reduxValueWithStorage, storageType]);

  return useMemo(() => {
    if (reduxValueWithStorage == null) {
      return keyDefault;
    }

    // it's possible for browser storage to have a non parsable value
    try {
      return JSON.parse(reduxValueWithStorage);
    } catch (err) {
      console.error(err);
      return keyDefault;
    }
  }, [keyDefault, reduxValueWithStorage]);
}
