import { GridLayoutId, HexId, RichTextDocument } from "@hex/common";

import { CellFilterShowType } from "../components/sidebar/constants.js";

import {
  BrowserStorageKey,
  useBrowserStorageGetter,
  useBrowserStorageSetter,
  useBrowserStorageValue,
} from "./useBrowserStorage.js";

export type SessionStorageKey<V> = {
  keyName: string;
  default: V;
};

export interface StoredScrollState {
  /** The hexId for which the scroll state was saved */
  hexId: HexId | null;
  /** The last vertical scroll position */
  scrollPosition: number | null;
  /** For layout view, the last active tab */
  layoutActiveTabId?: GridLayoutId | null;
}

/**
 * sessionStorage is similar to localStorage;
 * the difference is that while data in localStorage doesn't expire,
 * data in sessionStorage is cleared when the page session ends
 */
export const SessionStorageKeys = {
  SCROLL_STATE: (
    view: "logic" | "layout",
  ): SessionStorageKey<StoredScrollState> => ({
    keyName: `${view}_scroll_state`,
    default: {
      hexId: null,
      scrollPosition: null,
    },
  }),
  REVIEW_REQUEST_EDITOR: (
    reviewRequestLinkId: string,
  ): SessionStorageKey<null | RichTextDocument> => ({
    keyName: `review_request_editor_${reviewRequestLinkId}`,
    default: null,
  }),
  APP_SIDEBAR_VIEW: (
    hexId: HexId,
  ): BrowserStorageKey<"comments" | "schedules" | "bookmarks" | null> => ({
    keyName: `app_sidebar_${hexId}`,
    default: null,
  }),
  CELL_FILTER_SHOW_TYPE: (
    hexId: HexId,
  ): SessionStorageKey<
    (typeof CellFilterShowType)[keyof typeof CellFilterShowType]
  > => ({
    keyName: `cell_filter_show_type_${hexId}`,
    default: CellFilterShowType.All,
  }),
  PROJECT_REPLACE_OPENED: (hexId: HexId): SessionStorageKey<boolean> => ({
    keyName: `project_replace_opened_${hexId}`,
    default: false,
  }),
};

export function useSessionStorageGetter<V>(key: BrowserStorageKey<V>): () => V {
  return useBrowserStorageGetter("Session", key);
}
export function useSessionStorageSetter<V>(
  key: BrowserStorageKey<V>,
): (value: ((oldValue: V) => V) | V) => void {
  return useBrowserStorageSetter("Session", key);
}
export function useSessionStorageValue<V>(key: BrowserStorageKey<V>): V {
  return useBrowserStorageValue("Session", key);
}

export function useSessionStorage<V>(
  key: BrowserStorageKey<V>,
): [V, (value: V) => void] {
  const value = useSessionStorageValue(key);
  const setter = useSessionStorageSetter(key);

  return [value, setter];
}
